@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Familjen+Grotesk:wght@500&family=Montserrat&display=swap');


.App{
  font-family: 'Montserrat', sans-serif;
  background-color: whitesmoke;
}
.font{
  font-family: 'Familjen Grotesk', sans-serif;
}
.navbar {
  position: fixed;
  top: 0;
  height: 80px;
 
  transition: top 0.4s;
  z-index: 1000;
}

.navbar--hidden {
  top: -80px;
}
.tabel {
  --sb-track-color: #0c80b5;
  --sb-thumb-color: #2c2c2c;
  --sb-size: 10px;

  scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
}

.tabel::-webkit-scrollbar {
  width: var(--sb-size);
}

.tabel::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 10px;
}

.tabel::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 5px; /* Adjust this value to reduce the height of the thumb */
}

.but {
  padding: 1em 2em;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  color: #2c9caf;
  background-color: transparent;
  transition: all 1000ms;
  font-size: 15px;
  position: relative;
  overflow: hidden;
  outline: 2px solid #2c9caf !important;
}

.but:hover {
  color: #ffffff;
  transform: scale(1.1);
  outline: 2px solid #70bdca;
  box-shadow: 4px 5px 17px -4px #268391;
}

.but::before {
  content: "";
  position: absolute;
  left: -50px;
  top: 0;
  width: 0;
  height: 100%;
  background-color: #2c9caf;
  transform: skewX(45deg);
  z-index: -1;
  transition: width 1000ms;
}

.but:hover::before {
  width: 250%;
}

.selected {
  position: relative;
  background-color: #28a745; /* Bootstrap success color */
  border-radius: 5px;
  box-shadow: #218838 0px 4px 0px 0px; /* Adjusted shadow color */
  padding: 15px;
  background-repeat: no-repeat;
  box-sizing: border-box;
  height: 49px;
  color: #fff;
  border: none;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  overflow: hidden;
}

.selected::before {
  content: "";
  background-color: #218838; /* Adjusted color for before pseudo-element */
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: width 500ms ease-in-out;
  display: inline-block;
}

.selected:hover::before {
  width: 100%;
}
.pushable {
  position: relative;
  background: transparent;
  padding: 0px;
  border: none;
  cursor: pointer;
  outline-offset: 4px;
  outline-color: deeppink;
  transition: filter 250ms;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.shadow {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: hsl(226, 25%, 69%);
  border-radius: 8px;
  filter: blur(2px);
  will-change: transform;
  transform: translateY(2px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

.edge {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(
    to right,
    hsl(248, 39%, 39%) 0%,
    hsl(248, 39%, 49%) 8%,
    hsl(248, 39%, 39%) 92%,
    hsl(248, 39%, 29%) 100%
  );
}

.front {
  display: block;
  position: relative;
  border-radius: 8px;
  background: hsl(248, 53%, 58%);
  padding: 16px 32px;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 1rem;
  transform: translateY(-4px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

.pushable:hover {
  filter: brightness(110%);
}

.pushable:hover .front {
  transform: translateY(-6px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}

.pushable:active .front {
  transform: translateY(-2px);
  transition: transform 34ms;
}

.pushable:hover .shadow {
  transform: translateY(4px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}

.pushable:active .shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}

.pushable:focus:not(:focus-visible) {
  outline: none;
}


.blue{
    color: blue;
}
.zoom {
    transition: transform .5s;
  }
  
  .zoom:hover { 
    transform: scale(1.15); 
  }


.max-width-400 {
  max-width: 800px; /* Adjust this value as needed */
}
.accordion-button{
font-weight: bolder;
}


.accordion-button:not(.collapsed)
{
  background-color: transparent !important;
  box-shadow: none !important;
}
.accordion-body{
  border-top: 1px solid black;

}
.accordion-button:focus{
  outline: none !important;
  box-shadow: none !important;
  border-color: none !important;
}
.heading{
  font-size: 3rem;
  font-family: 'Familjen Grotesk', sans-serif;
}
.faq{
  width: 75%;
}
.backk{
  background-image: url(images/contactbg.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(5px);
}
.bg-image{
  background-image: url(images/landingbg1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
 .overlay{
  /* border: 5px solid black; */
  background-color: #898b8d20;
  border-radius: 20px;
 backdrop-filter: blur(5px); 
}
.aboutbg {
  background-image: url("./images/aboutbg1.jpg");
  background-position: inherit;
  background-size: cover;
  backdrop-filter: blur(50px); 
}

.overlay-about {
  border-radius: 20px;
  backdrop-filter: blur(5px) brightness(0.5); /* Blur effect and brightness filter */
}

.about-text {
  color: white;
  padding: 20px;
}


.about-text {
  color: white;
  padding: 20px;
  text-emphasis-color: white; 
}



.gradient-list {
  list-style-type: none;
  counter-reset: gradient-counter;
  padding-left: 0;
}

.gradient-item {
  position: relative;
  background-color: transparent;
  border: none;
  padding-left: 3.5em;
  font-size: 1.2rem;
   /* Adjust the spacing as needed */
}
/* .accordion-button{
  font-size: 1.2rem !important;
} */
.gradient-item::before {
  content: counter(gradient-counter);
  counter-increment: gradient-counter;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(to right, #52acf6, #1c6060); /* Gradient colors */
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5em;
}

.custom-card {
  transition: transform 0.5s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: none;
}

.custom-card:hover {
  transform: translateY(-10px);
}
.card-icon {
  transition: transform 0.5s; /* Added unit 's' for seconds */
}

.card-icon:hover {
  transform: scale(0.9);
}
.cardtitle{
  cursor: pointer;
  transition: color 0.2s;
}
.cardtitle:hover{
  color: #1d99d2;
}

/* .card-icon {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background: #2c3e50;
  background: -webkit-linear-gradient(to bottom, #3498db, #2c3e50);
  background: linear-gradient(to bottom, #3498db, #2c3e50); 
  height: fit-content;
  width: fit-content;
  margin: auto;
  z-index: 1; /* Ensure the icon is above other elements 
} */


.testbg{
  background-image: url("./images/testbg1.jpg");
  background-position:inherit;
  background-size:cover;
}
.otherbg{
  background-color: rgb(239,242,248);

}


.dropmenu{
  color: gray;
  font-weight: bold !important;
  transition: color 0.3s;
}
.dropmenu:hover{
  color: black
}
.blockquote-footer {
  color: #868e96;
 }
 
 .swiper-container {
  margin-top: 30px;
 }
 
 .swiper-button-next,
 .swiper-button-prev {
  color: #868e96;
  font-size: 1.5rem;
 }
 .shad{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
 }
 .testimonial-shadow{
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
 }
 .table>:not(caption)>*>* {
  padding: 20px 40px;
 }
.text-instagram{
  background: #f09433; 
background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

.btn_center {
  display: table-cell;
  vertical-align: middle;
  border: none;
  background-color: transparent;
}

.btn_center {
  color: white;
  margin: auto;
  width: fit-content;
  margin-bottom: 1rem;
  line-height: 50px;
  font-size: 15px;
  letter-spacing: 2px;
  border-radius: 50px;
  font-weight: bold;
  text-transform: capitalize;
  background-color: #123db4;
  box-shadow: 0px 15px 18px -6px rgba(35, 22, 214, 0.65);
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  position: relative;
  border: none
}

.btn_center:hover {
  background-color: #051749;
  box-shadow: 0px 22px 19px -8px rgba(16, 8, 119, 0.65);
  transform: scale(1.02,1.02);
}

.btn_center:active {
  background-color: #051749;
  box-shadow: 0px 12px 18px -4px rgba(7, 4, 51, 0.65);
  transform: scale(0.95,0.95);
  transition: all 0.4s -0.125s;
}

.btn_center_green {
  color: white;
  margin: auto;
  width: fit-content;
  margin-bottom: 1rem;
  font-size: 15px;
  letter-spacing: 2px;
  border-radius: 50px;
  font-weight: bold;
  text-transform: capitalize;
  background-color: #4caf50; /* Updated to green */
  box-shadow: 0px 15px 18px -6px rgba(35, 22, 214, 0.65);
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  position: relative;
  border:none;
}

.btn_center_green:hover {
  background-color: #45a049; /* Updated to green on hover */
  box-shadow: 0px 22px 19px -8px rgba(9, 179, 86, 0.65);
  transform: scale(1.02, 1.02);
}

.btn_center_green:active {
  background-color: #45a049; /* Updated to green on active */
   box-shadow: 0px 12px 18px -4px rgba(7, 4, 51, 0.65); 
  transform: scale(0.95, 0.95);
  transition: all 0.4s -0.125s;
}

.butback {
  background-color: #ffffff00;
  color: black;
  width: auto;
  height: 2.9em;
  border: none;
  border-radius: 11px;
  text-align: right;
  transition: all 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.butback:hover {
  background-color: #000000;
  cursor: pointer;
  color: white;
}

.butback .iconback {
  transition: all 0.4s ease;
}


.butback:hover .iconback {
  transform: translateX(-10px);
}


@media screen and (max-width: 768px) {
  .faq{
    width: 90% ;
  }
  .zoom:hover{
    transform: scale(1); 
  }
}




 